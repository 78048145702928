@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* background-color: black; */
  color: white;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  /* transition: all .6s ease-in-out; */
  line-height: 1.3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-gradient-bg {
  background-color: #010816;
  /* background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%); */
  /* background-blend-mode: multiply; */
}

.big-heading {
  margin: 0px;
  font-size: clamp(40px, 9vw, 65px);
  font-weight: 700;
  letter-spacing: -0.5px;
  text-shadow: 0px 6px 5px rgba(150, 150, 150, 0.3);
}
.md-texts {
  font-size: clamp(11px, 10vw, 18px) !important;
  letter-spacing: -0.3px !important;
}
.texts {
  font-size: clamp(15px, 8vw, 18px) !important;
  letter-spacing: 0px !important;
}
.logo-clamp {
  width: clamp(130px, 12vw, 160px) !important;
  letter-spacing: -0.5px !important;
}
.main-transition {
  transition: all 0.9s ease-in-out;
}
.secondary-transition {
  transition: all 0.5s ease-in-out;
}
.bgImg {
  background-image: url("./assets/webp/skyhigh-5.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: inherit;
}
.it-text-shadow {
  text-shadow: 0px 6px 5px rgba(150, 150, 150, 0.3);
}
